import loadable from '@loadable/component';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import ym from 'react-yandex-metrika';
import styled, { css } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';

import { Col, Container, Row } from 'react-bootstrap';

import { useTranslation } from '../components/hooks/useTranslation';
import {
  GQL_GET_HOME, GQL_GET_ME, GQL_GET_PAGE, GQL_GET_SLIDERS,
} from '../graphql/queries';
import { SECTION_MAIN } from '../helpers/main';
import { yEvent } from '../components/Tracker';
import { RU_DOMAIN } from '../locales';

const SlickSlider = loadable(() => import('../components/SlickSlider'));
const MetaTags = loadable(() => import('../components/MetaTags'));
const ErrorScreen = loadable(() => import('./Error'));

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [height, setHeight] = useState(0);
  const [me, setMe] = useState({});

  const { data: dataMe } = useQuery(GQL_GET_ME, { fetchPolicy: 'cache-only' });

  useEffect(() => {
    ym('hit', '/');
  }, []);

  useEffect(() => {
    if (_.get(dataMe, 'me._id')) {
      setMe(_.get(dataMe, 'me'));
    }
  }, [dataMe]);

  const { data, error, loading } = useQuery(GQL_GET_PAGE, {
    variables: { url: '/' },
    fetchPolicy: 'network-only',
  });

  const errors = _.get(error, 'graphQLErrors', []);

  const model = _.get(data, 'page', {});

  useEffect(() => {
    if (_.get(me, '_id')) {
      history.push('/account/posts');
    }
  }, [_.get(me, '_id')]);

  if (!_.isEmpty(errors)) {
    return <ErrorScreen errors={errors} />;
  }

  if (!loading && _.isEmpty(model)) {
    return <ErrorScreen errors={[]} />;
  }

  const { data: main } = useQuery(GQL_GET_SLIDERS, {
    variables: {
      section: SECTION_MAIN,
      fetchPolicy: 'cache-first',
    },
  });

  const { data: mainPage } = useQuery(GQL_GET_HOME, {
    variables: {
      section: 'main',
      fetchPolicy: 'cache-first',
    },
  });

  const mainPageData = _.get(mainPage, 'home', []);

  const sliders = _.get(main, 'sliders', []);

  const getDimensions = () => setHeight(window.innerHeight);

  const loginTariff = () => {
    localStorage.setItem('goto', '/account/orders-artist/tariff');
    history.push('/login');
  };

  const goCollection = () => {
    history.push('/collections');
  };

  useEffect(() => {
    if (height === 0) {
      getDimensions();
    }
    window.addEventListener('resize', getDimensions);
    return () => {
      setHeight(0);
      window.removeEventListener('resize', getDimensions);
    };
  }, []);

  const metrica = (target) => {
    yEvent(target);
  };

  const sizesSlider = {
    xl: 7000,
    lg: 1170,
    md: 1024,
    sm: 812,
  };

  const slidesSlider = {
    xl: 1,
    lg: 1,
    md: 1,
    sm: 1,
  };

  const metaTags = model.meta
    ? {
      title: model.meta.title,
      description: model.meta.description,
      path: RU_DOMAIN,
      updated: model.updated_at,
      created: model.created_at,
      image: _.get(sliders, '0.mainImg', null),
    }
    : null;

  let bgImage = '';

  if (_.get(model, 'cover.key')) {
    bgImage = `/files/${_.get(model, 'cover.key')}`;
  }

  return (
    <HomePage>
      {metaTags && <MetaTags {...metaTags} />}
      {loading && (
        <div className="loader">
          <div className="bar" />
        </div>
      )}
      {sliders && !_.isEmpty(sliders) && (
        <MainSlider>
          <SlickSlider
            infinite
            // autoplay
            speed={800}
            autoplaySpeed={3000}
            className="banner"
            sizes={sizesSlider}
            slidesCount={slidesSlider}
            items={sliders.map((slide) => (
              <SlideWrap height={height} key={`slide-${slide._id}`}>
                <Background
                  mobile={_.get(slide, 'mobileImg', null)}
                  image={_.get(slide, 'mainImg', null)}
                />
                <Container>
                  <Row>
                    <ColText lg={6} md={7} sm={8} xs={12}>
                      <TextBlock color={slide.text_color || css`var(--art-dark)`}>
                        <H1Slide>{slide.name}</H1Slide>
                        {slide.description ? (
                          <H2Slide
                            dangerouslySetInnerHTML={{
                              __html: slide.description,
                            }}
                          />
                        ) : (
                          <br />
                        )}
                        {slide.button
                          && (slide.button_url.includes('http')
                            || slide.button_url.includes('www')) && (
                            <Button
                              rel="nofollow noreferrer noopener"
                              color={slide.button_color || css`var(--art-active)`}
                              href={slide.button_url}
                              target="_blank"
                            >
                              {slide.button}
                            </Button>
                        )}
                        {slide.button
                          && !slide.button_url.includes('http')
                          && !slide.button_url.includes('www') && (
                            <ButtonLink
                              color={slide.button_color || css`var(--art-active)`}
                              text="white"
                              to={slide.button_url}
                            >
                              {slide.button}
                            </ButtonLink>
                        )}
                      </TextBlock>
                    </ColText>
                    <Col lg={6} md={5} sm={4} xs={12} />
                  </Row>
                </Container>
              </SlideWrap>
            ))}
          />
        </MainSlider>
      )}
      {mainPageData && !_.isEmpty(mainPageData) && (
        <Wrap id="wrap" image={bgImage || undefined}>
          {mainPageData.map((block) => {
            const image = `/files/${_.get(block, 'cover.key')}`;
            const image_png = `/files/${_.get(block, 'image.key')}`;
            return (
              <BgStyled key={block._id}>
                <BG view={!bgImage ? 1 : undefined} image={image || undefined} />
                <StyleContainer>
                  {_.get(block, 'image.key') && (
                    <Image src={image_png} alt={block.name} />
                  )}
                  <TextWrap>
                    <H2>{block.name}</H2>
                    {block.description && (
                      <H3Home
                        dangerouslySetInnerHTML={{
                          __html: block.description,
                        }}
                      />
                    )}
                    {block.button
                    && (block.button_url.includes('http') || block.button_url.includes('www')) && (
                      <HrefBtn
                        href={block.button_url}
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                      >
                        {block.button}
                      </HrefBtn>
                    )}
                    {block.button
                    && !block.button_url.includes('http')
                    && !block.button_url.includes('www') && (
                      <BtnBlock>
                        <LinkBtn to={block.button_url}>{block.button}</LinkBtn>
                        {block.button_url.includes('market') && (
                          <AddButton type="button" onClick={loginTariff}>
                            {t('button_add_artwork', { ns: 'index' })}
                          </AddButton>
                        )}
                        {block.button_url.includes('artwork') && (
                          <AddButton type="button" onClick={goCollection}>
                            {t('button_add_collection', { ns: 'index' })}
                          </AddButton>
                        )}
                      </BtnBlock>
                    )}
                  </TextWrap>
                </StyleContainer>
              </BgStyled>
            );
          })}
        </Wrap>
      )}
      {(!_.get(me, '_id') || !me._id) && !loading && (
        <RowRegistration>
          <RegistrationBox>
            <Name>{t('login_or_register', { ns: 'index' })}</Name>
            <Buttons>
              <ButtonLink
                text="white"
                color={css`var(--art-active)`}
                to="/login"
                onClick={() => metrica('authorization')}
              >
                {t('login', { ns: 'index' })}
              </ButtonLink>
              <ButtonLink
                text={css`var(--art-active)`}
                color="white"
                to="/signup"
                onClick={() => metrica('registr')}
              >
                {t('registration', { ns: 'index' })}
              </ButtonLink>
            </Buttons>
          </RegistrationBox>
        </RowRegistration>
      )}
    </HomePage>
  );
};

const BtnBlock = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  > button {
    margin-left: 20px;
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    a,
    button {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
`;

const HomePage = styled.div`
  margin-top: -100px;
  @media screen and (max-width: 950px) {
    margin-top: 15px;
  }
`;

const Image = styled.img`
  max-width: 600px;
  width: 100%;
`;

const RowRegistration = styled.div`
  background-color: #e0e5f1;
  @media screen and (max-width: 1024px) {
    margin: 0 -15px;
  }
  @media screen and (max-width: 500px) {
    margin: 0;
  }
`;

const StyleContainer = styled.div`
  padding: 90px 0;
  max-width: 760px;
  margin: 0 auto;
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  border-bottom: 2px solid #b9c1d7;
  @media screen and (max-width: 768px) {
    padding: 90px 84px;
  }
  @media screen and (max-width: 500px) {
    padding: 60px 0;
    margin: 0 20px;
  }
`;

const TextWrap = styled.div`
  width: 100%;
  position: relative;
  color: #000000;
  z-index: 2;
`;

const ColText = styled(Col)`
  @media screen and (min-width: 812px) {
    padding: 0;
  }
  @media screen and (max-width: 812px) and (orientation: landscape) {
    padding-left: 20px;
  }
`;

const BG = styled.div`
  &:before {
    content: ${({ view }) => (view ? '""' : 'none')};
    position: absolute;
    background-image: url(${({ image }) => image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &:after {
    content: ${({ view }) => (view ? '""' : 'none')};
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1;
    top: 0;
    left: 0;
  }
`;

const renderImage = (image) => css`
  &:before {
    content: '';
    background-image: url(${image});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 768px) {
      background-size: 100%;
      background-repeat: repeat-y;
    }
  }
`;

const Wrap = styled.div`
  display: block;
  position: relative;
  overflow: hidden;
  ${({ image }) => (image ? renderImage(image) : '')}
`;

const BgStyled = styled.div`
  position: relative;
  &:last-child {
    > div {
      border-bottom: 0;
    }
  }
`;

const Name = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 120%;
  margin-bottom: 40px;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
    padding-right: 0;
  }
`;

const RegistrationBox = styled.div`
  width: 100%;
  height: 300px;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media screen and (max-width: 812px) {
    padding: 25px;
    a {
      margin-bottom: 20px;
    }
  }
`;

const TextBlock = styled.div`
  color: ${({ color }) => color};
  padding-left: 0;
  @media screen and (max-width: 1344px) {
    padding-left: 60px;
  }
  @media screen and (max-width: 768px) {
    padding-left: 35px;
  }
  @media screen and (max-width: 812px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 500px) and (orientation: portrait) {
    margin-top: -100px;
  }
  @media screen and (max-width: 812px) and (orientation: landscape) {
    max-width: 295px;
    margin-top: 88px;
  }
`;

const MainSlider = styled.div`
  position: relative;
  .banner {
    .slick-list {
      margin: 0;
    }
    .slick-next,
    .slick-prev {
      top: 50%;
      right: inherit;
      z-index: 100;
      background: transparent;
      &:before {
        width: 20px;
        height: 20px;
        border-right-width: 2px;
        border-bottom-width: 2px;
      }
    }
    .slick-prev {
      left: 0;
    }
    .slick-next {
      right: 0;
    }
  }
  @media screen and (max-width: 950px) {
    margin-top: -80px;
  }
`;

const SlideWrap = styled.div`
  height: ${({ height }) => height}px;
  flex-direction: row;
  width: 100%;
  display: inline-flex !important;
  align-items: center;
  position: relative;
  min-height: 700px;
`;

const H1Slide = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 120%;
  @media screen and (max-width: 1344px) {
    font-size: 32px;
  }
  @media screen and (max-width: 950px) and (orientation: landscape) {
    font-size: 24px;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
    line-height: 110%;
  }
`;

const H2Slide = styled.h2`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin: 30px 0;
  max-width: 530px;
  @media screen and (max-width: 950px) and (orientation: landscape) {
    font-size: 16px;
  }
  @media screen and (max-width: 812px) {
    padding-right: 0;
    font-size: 14px;
    line-height: 20px;
  }
`;

const H3Home = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 1024px) {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

const Background = styled.div`
  background-size: cover;
  background-image: url(${({ image }) => image || ''});
  background-repeat: no-repeat;
  background-position: right top;
  position: absolute;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  @media screen and (max-width: 812px) {
    background-size: cover;
  }
  @media screen and (max-width: 1024px) and (orientation: portrait) {
    background-position-x: 70%;
  }
  @media screen and (max-width: 700px) and (orientation: landscape) {
    background-position-x: 5%;
    top: 0;
  }
  @media screen and (max-width: 500px) and (orientation: portrait) {
    background-image: url(${({ mobile }) => mobile || ''});
    background-position-x: 60%;
  }
  @media screen and (max-width: 320px) and (orientation: portrait) {
    background-position-x: 22%;
  }
`;

const BTMain = css`
  padding: 1.1rem 6.2rem;
  width: 100%;
  color: ${({ text }) => text};
  background-color: ${({ color }) => color};
  transition: all 150ms linear;
  &:first-of-type {
    margin-right: 20px;
  }
  &:hover,
  &:focus {
    color: ${({ text }) => text};
    text-decoration: none;
    transition: all 150ms linear;
    filter: brightness(80%);
  }
  @media screen and (max-width: 1024px) {
    padding: 12px 21px;
  }
  @media screen and (max-width: 500px) {
    &:first-of-type {
      margin-right: 0;
    }
    width: 100%;
  }
`;

const ButtonLink = styled(Link)`
  ${BTMain}
`;

const Button = styled.a`
  ${BTMain}
`;

const Buttons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 500px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

const BT = css`
  padding: 10px 40px;
  background-color: var(--art-active);
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  border-radius: 3px;
  display: inline-block;
  margin-top: 20px;
  transition: all 300ms linear;
  &:hover,
  &:focus {
    text-decoration: none;
    color: white;
    background-color: var(--art-active-light);
    transition: all 300ms linear;
  }
  @media screen and (max-width: 812px) {
    margin-top: 16px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 20px;
  }
`;

const LinkBtn = styled(Link)`
  ${BT}
`;

const HrefBtn = styled.a`
  ${BT}
`;

const AddButton = styled.button`
  ${BT};
  background-color: #c5d5ff;
  color: var(--art-active);
`;

const H2 = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 120%;
  margin: 30px 0;
  @media screen and (max-width: 1024px) {
    font-size: 32px;
  }
  @media screen and (max-width: 812px) {
    font-size: 24px;
    margin-top: 20px;
  }
`;

export default Home;
